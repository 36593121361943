















































































import { Component, Vue } from 'vue-property-decorator';
import { dispatchGetSeTasks, dispatchCreateSeTask } from '@/store/se-tasks/actions';
import { ISeTaskCreate } from '@/interfaces/se-tasks';
import objectContaining = jasmine.objectContaining;
import { readOneSeTask } from '@/store/se-tasks/getters';

@Component
export default class CreatePlugCheckerTask extends Vue {
  public spidersList = ['google', 'yandex_ru'];

  public valid = false;
  public name: string = '';
  public description: string = '';
  public sources: string[] = [];
  public deep: number = 20;
  public file: File|null = null;
  public taskId?: number;

  public async mounted() {
    await dispatchGetSeTasks(this.$store);
    this.reset();
  }

  public reset() {
    this.name = '';
    this.description = '';
    this.$validator.reset();
    this.taskId = this.task?.id;
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    // if (await this.$validator.validateAll()) {
    //   const createdTask: ISeTaskCreate = {
    //     name: this.name,
    //     sources: this.sources,
    //     deep: this.deep,
    //     file: this.file,
    //   };
    //   if (this.description) {
    //     createdTask.description = this.description;
    //   }
    //   await dispatchCreateSeTask(this.$store, createdTask);
    //   this.$router.push('/main/se-tasks');
    // }
  }

  get task() {
    // console.log('taskId -->', this.$router.currentRoute.params.id)
    return readOneSeTask(this.$store)(+this.$router.currentRoute.params.id);
  }
}
